import "./slider.css";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

export default function BtnSlider({ direction, moveSlide }) {
  return (
    <button
      onClick={moveSlide}
      style={{border: 'none', backgroundColor: 'transparent', color: 'white', cursor: 'pointer'}}
    >
      {
        direction === "next" ?
        <IoIosArrowForward style={{height: '20px', width:'20px'}} /> :
        <IoIosArrowBack style={{height: '20px', width:'20px'}}  />
      }
    </button>
  );
}