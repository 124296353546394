import React from "react";
import '../../assets/css/styles.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import history from "../../utilities/history";
import {firstLetter} from "../../utilities/util";
import store from "../../app/store";
import {setKategorijaTipSearchParam, setPolSearchParams} from "../../app/store/searchParams/actions";
import { IoMdClose } from 'react-icons/io';

function SubHeader(props) {
    return (
        <div className="d-flex justify-content-center ">
            <div className="sub-header">
            <div style={{ position: 'absolute', top: '15px', right: '15px'}}>
            <IoMdClose style={{color: 'black', height:'50px', width:'50px', cursor: 'pointer'}} onClick={()=> props.onAbort()}/>
            </div>
            <Container className="header-on-mouse-hover">
                <p className="subheader-title pt-4 mb-0 ff-syncopate-bold ps-2" style={{fontSize: '32px'}}>ZA NJU</p>
                <div className="d-flex flex-wrap">
                    {
                        props.data &&
                        props.data.woman.map((item) => {
                            const label = item.label === 'Kupaći kostimi i plažni program' ? `Kupaći kostimi` : item.label;
                            return (
                                <div className='px-2 mb-3' style={{minWidth: '50px'}}>
                                    <p
                                        style={{cursor: 'pointer', fontSize: '24px'}}
                                        className={label.length > 20 ? 'fs-6 mt-2 sub-menu-item fw-bold mb-0' : 'mb-0 fw-bold fs-6 sub-menu-item sub-item'}
                                        onClick={() => {
                                            store.dispatch(setKategorijaTipSearchParam(item.value, 0));
                                            history.push({pathname: '/product-list'});
                                        }}>{label}</p>
                                    {
                                        item.tip.map((type) => {
                                            return (
                                                <p
                                                    style={{cursor: 'pointer', fontSize: '16px'}}
                                                    className='fs-6 sub-menu-item mb-1'
                                                    onClick={() => {
                                                        store.dispatch(setPolSearchParams('zenski'));
                                                        store.dispatch(setKategorijaTipSearchParam(item.value, type.value));
                                                        history.push({pathname: '/product-list'});
                                                    }}>{firstLetter(type.label)}</p>
                                            );
                                        })
                                    }
                                </div>
                            );
                        })
                    }
                </div>
                <p className="subheader-title mb-0 ff-syncopate-bold ps-2 pt-1">ZA NJEGA</p>
                <div className="d-flex flex-wrap">
                    {
                        props.data &&
                        props.data.man.map((item) => {
                            const label = item.label === 'Kupaći kostimi i plažni program' ? `Kupaći kostimi` : item.label;
                            return (
                                <div className='px-2 pb-4' style={{minWidth: '50px'}}>
                                    <p
                                        style={{cursor: 'pointer'}}
                                        className={label.length > 20 ? 'fs-6 mt-2 sub-menu-item fw-bold mb-0' : 'mb-0 fw-bold fs-6 sub-menu-item sub-item'}
                                        onClick={() => {
                                            store.dispatch(setKategorijaTipSearchParam(item.value, 0));
                                            history.push({pathname: '/product-list'});
                                        }}>{label}</p>
                                    {/*<hr/>*/}
                                    {
                                        item.tip.map((type) => {
                                            return (
                                                <p
                                                    style={{cursor: 'pointer'}}
                                                    className='fs-6 sub-menu-item mb-1'
                                                    onClick={() => {
                                                        store.dispatch(setPolSearchParams('muski'));
                                                        store.dispatch(setKategorijaTipSearchParam(item.value, type.value));
                                                        history.push({pathname: '/product-list'});
                                                    }}>{firstLetter(type.label)}</p>
                                            );
                                        })
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </Container>
        </div>
        </div>
    );
}

export default SubHeader;

